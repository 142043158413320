import ReactDOM from 'react-dom/client';

//
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

// const store = createStore(() => ({
//   user: {
//     family_name: 'ABang',
//     nickname: "God of War",
//     given_name: 'Kratos',
//     name: 'Obi Kratos Abang',
//     email: 'user.email',
//     picture: 'user.picture',
//   },
// }));
root.render(
  <Auth0Provider
    domain="https://tekstedia.us.auth0.com"
    clientId="Owg4ofPICqrqcW2fe1jzFwnNPgpXR0Fu"
    authorizationParams={{
      redirect_uri: 'http://apply.tekstedia.com',
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
