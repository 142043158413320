import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
  // const location = useLocation();
  // const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const encodedUser = queryParams.get('user');
  const decodedUser = JSON.parse(decodeURIComponent(encodedUser));
  useEffect(() => {
    window.localStorage.setItem('user', JSON.stringify(decodedUser));
    // queryParams.delete('user');
    // navigate.replace({
    //   search: queryParams.toString(),
    // });
  }, []);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
