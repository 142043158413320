import PropTypes from 'prop-types';
import {useState, useEffect }from "react";
import { Typography, Box, Card, Avatar, TextField, Input } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import account from '../../../_mock/account';
import Iconify from '../../../components/iconify';

// @mui
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------



export default function AppProfile() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(user);
    }
  }, []);
  const [name,setName] = useState(user?.name)
  const [email,setEmail] = useState(user?.email)
  const [cv,setCV] = useState("Betrandcliff.pdf")

  const [nameEdit,setNameEdit] = useState(false)
  const [emailEdit,setEmailEdit] = useState(false)
  const [cvEdit,setCvEdit] = useState(false)

  return (
    <Card>
      <Avatar src={user?.picture} alt="photoURL" />
      <Box
        sx={{
          width: {
            xxs: '100%',
            xs: '100%',
            sm: '80%',
          },
        }}
      >
        <Box display="flex" alignItems="center" mt="1rem">
          <Typography flex={0.5} paddingRight=" 10px">
            Name:{' '}
          </Typography>
          <Box flex={4} display="flex" justifyContent="space-between" alignItems="center">
            {nameEdit === false ? (
              <Typography
                sx={{
                  paddingLeft: {
                    xxs: '10px',
                    xs: '10px',
                    sm: '0px',
                  },
                }}
              >
                {name}
              </Typography>
            ) : (
              <TextField label="Name" onChange={(e) => setName(e.target.value)} />
            )}
            {nameEdit === false ? (
              <Iconify icon="icon-park:right" width={24} height={24} onClick={() => setNameEdit(!nameEdit)} />
            ) : (
              <Iconify icon="icon-park:right" width={24} height={24} onClick={() => setNameEdit(!nameEdit)} />
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mt="1rem">
          <Typography flex={0.5} paddingRight=" 10px">
            Email:{' '}
          </Typography>
          <Box flex={4} display="flex" justifyContent="space-between" alignItems="center">
            {emailEdit === false ? (
              <Typography
                sx={{
                  paddingLeft: {
                    xxs: '10px',
                    xs: '10px',
                    sm: '0px',
                  },
                }}
              >
                {email}
              </Typography>
            ) : (
              <TextField label="Email" onChange={(e) => setEmail(e.target.value)} />
            )}
            {emailEdit === false ? (
              <Iconify icon="icon-park:right" width={24} height={24} onClick={() => setEmailEdit(!emailEdit)} />
            ) : (
              <Iconify icon="icon-park:right" width={24} height={24} onClick={() => setEmailEdit(!emailEdit)} />
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mt="1rem">
          <Typography flex={0.5} paddingRight=" 10px">
            CV:{' '}
          </Typography>
          <Box flex={4} display="flex" justifyContent="space-between" alignItems="center">
            {cvEdit === false ? (
              <Typography
                sx={{
                  paddingLeft: {
                    xxs: '10px',
                    xs: '10px',
                    sm: '0px',
                  },
                }}
              >
                {cv}
              </Typography>
            ) : (
              <Input disableUnderline type="file" onChange={(e) => setCV(e.target.files[0].name)} />
            )}
            {cvEdit === false ? (
              <Iconify icon="icon-park:right" width={24} height={24} onClick={() => setCvEdit(!cvEdit)} />
            ) : (
              <Iconify icon="icon-park:right" width={24} height={24} onClick={() => setCvEdit(!cvEdit)} />
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
