// userReducer.js
const urlParams = new URLSearchParams(window.location.search);
  const encodedUser = urlParams.get('user');
  const decodedUser = JSON.parse(decodeURIComponent(encodedUser));
// Initial state
const initialState = {
  user: decodedUser,
};

// Action types
const SET_USER = 'SET_USER';

// Action creators
export function setUser(user) {
  return {
    type: SET_USER,
    payload: user,
  };
}

// Reducer
export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
