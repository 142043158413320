import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import Iconify from '../../../components/iconify';


const AppInterviews = ({ interviews }) => {
  const [currentInterviewIndex, setCurrentInterviewIndex] = useState(0);
  const handleNextInterview = () => {
    setCurrentInterviewIndex((prevIndex) => (prevIndex + 1) % interviews.length);
  };

  const handlePreviousInterview = () => {
    setCurrentInterviewIndex((prevIndex) => (prevIndex - 1 + interviews.length) % interviews.length);
  };

  const currentInterview = interviews[currentInterviewIndex];
  const [value, setValue] = useState(dayjs(currentInterview.date));
  useEffect(() => {
    setValue(dayjs(currentInterview.date));
  }, [currentInterview]);
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Upcoming Interview</Typography>
        <Typography variant="subtitle1">{currentInterview.date}</Typography>
        <Typography variant="subtitle2">{currentInterview.time}</Typography>
        <Typography variant="body1">Interviewer: {currentInterview.interviewer}</Typography>
        {/* Add more details as needed */}

        <div>
          <IconButton onClick={handlePreviousInterview}>
            <Iconify icon="icon-park:left" width={24} height={24} />
          </IconButton>
          <IconButton onClick={handleNextInterview}>
            <Iconify icon="icon-park:right" width={24} height={24} />
          </IconButton>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateCalendar', 'DateCalendar']}>
            <DemoItem>
              <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </CardContent>
    </Card>
  );
};

export default AppInterviews;
