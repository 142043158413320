import { createStore, combineReducers } from 'redux';
import userReducer from './userReducer';

const rootReducer = combineReducers({
    user: userReducer,
    // other reducers...
  });
  
const store = createStore(rootReducer);

export default store;